import React from "react";


export function NavBar() {
    return (
        <div className="navbar bg-base-300">
            <div className="navbar-start">
                <button type="button" className="btn btn-ghost normal-case text-xl">Battle of the Centerländ - Server
                    Tester
                </button>
            </div>
            <div className="navbar-end">
                
            </div>
        </div>
    );
}

