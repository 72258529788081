import React, {Component} from 'react';
import './App.css';
import {NavBar} from "./components/NavBar";
import {LoadingAnimation} from "./components/LoadingAnimation";
import {Websocket, WebsocketBuilder, WebsocketEvents} from "websocket-ts";
import {Role} from "./messages/HelloServer";
import {InitialDialog} from "./components/InitialDialog";
import {Overview} from "./components/Overview";
import {SendPanel} from "./components/SendPanel";
import {MessagesDisplay} from "./components/MessagesDisplay";

type AppState = {
    initialDialog: boolean,
    connecting: boolean,
    connected: boolean,
    port: number,
    host: string,
    error: false | Event
    connectionError: false | CloseEvent,
    role: Role,
    name: string,
    receivedMessage: Array<{ message: string, type: string }>
}

class App extends Component<unknown, AppState> {

    private websocket?: Websocket;

    constructor(props: unknown) {
        super(props);
        this.state = {
            connected: false,
            connecting: false,
            initialDialog: true,
            host: "localhost",
            port: 3019,
            error: false,
            connectionError: false,
            role: "PLAYER",
            name: "Tester",
            receivedMessage: new Array<{ message: string, type: string }>()
        }
    }

    reset = () => {
        this.setState({
            connected: false,
            connecting: false,
            initialDialog: true,
            error: false,
            connectionError: false,
            receivedMessage: new Array<{ message: string, type: string }>()
        })
    }

    initializeConnection = () => {
        this.setState({connecting: true, initialDialog: false});
        const {port, host, receivedMessage} = this.state;
        this.websocket = new WebsocketBuilder(`ws://${host}:${port}`).build();
        this.websocket.addEventListener(WebsocketEvents.open, () => {
            this.setState({connecting: false, connected: true});
        })
        this.websocket.addEventListener(WebsocketEvents.close, (socket, closeEvent) => {
            console.log("Closed", closeEvent);
            this.setState({connecting: false, connectionError: closeEvent});
        })
        this.websocket.addEventListener(WebsocketEvents.error, (socket, event) => {
            console.log("Error", event);
            this.setState({error: event});
        })
        this.websocket.addEventListener(WebsocketEvents.message, (socket, event) => {
            receivedMessage.unshift({
                message: event.data as string,
                type: "Received",
            })
            this.setState({receivedMessage});

        })
    }

    sendMessage = (message: string) => {
        const {receivedMessage} = this.state;
        receivedMessage.push(
            {
                message,
                type: "Send",
            }
        );
        this.setState({receivedMessage});
        this.websocket?.send(message);
    }


    render() {
        const {
            name,
            receivedMessage,
            connected,
            role,
            connecting,
            error,
            connectionError,
            port,
            host,
            initialDialog
        } = this.state;
        return (
            <>
                <NavBar/>

                {connected ? (<>
                    <Overview host={host} port={port} name={name} role={role}/>
                    <SendPanel
                        onSend={this.sendMessage}
                        name={name}
                        role={role}
                        onClearLog={() => {
                            this.setState({receivedMessage: []});
                        }}
                    />
                    <MessagesDisplay messages={receivedMessage} onSend={this.sendMessage}/>
                </>) : null}
                <InitialDialog
                    initialDialog={initialDialog}
                    host={host}
                    port={port}
                    name={name}
                    role={role}
                    onHostChange={host => this.setState({host})}
                    onPortChange={port => this.setState({port})}
                    onNameChange={name => this.setState({name})}
                    onRoleChange={role => this.setState({role})}
                    onConnect={() => this.initializeConnection()}
                />

                <div className={`modal ${connecting && 'modal-open'}`}>
                    <div className="modal-box">
                        <h3 className="font-bold text-xl text-center"> Connecting...</h3>
                        <div className="flex items-center justify-center flex-col my-4 gap-4">
                            <code>ws://{host}:{port}</code>
                            <LoadingAnimation/>
                        </div>
                    </div>
                </div>
                <div className={`modal ${connectionError !== false ? 'modal-open' : ""}`}>
                    <div className="modal-box">
                        <h3 className="font-bold text-xl text-center">Connection Error</h3>
                        <div className="flex items-center justify-center flex-col my-4 gap-4">
                            <code>ws://{host}:{port}</code>
                            <div>
                                {connectionError ? connectionError.reason : null}
                            </div>
                        </div>
                        <div className="modal-action">
                            <button type="button" className="btn btn-sm btn-primary" onClick={() => {
                                this.reset();
                            }}>Retry
                            </button>
                        </div>
                    </div>
                </div>
                <div className={`modal ${error !== false ? 'modal-open' : ""}`}>
                    <div className="modal-box">
                        <h3 className="font-bold text-xl text-center">Websocket Error</h3>
                        <div className="flex items-center justify-center flex-col my-4 gap-4">
                            <code>ws://{host}:{port}</code>
                            <div>

                            </div>
                        </div>
                        <div className="modal-action">
                            <button type="button" className="btn btn-sm btn-primary" onClick={() => {
                                this.reset();
                            }}>Retry
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
            ;
    }


}

export default App;
