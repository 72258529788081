import {Component} from "react";
import {Role} from "../messages/HelloServer";
import copy from "copy-to-clipboard";

type OverviewProps = {
    host: string,
    port: number,
    name: string,
    role: Role
};

export class Overview extends Component<OverviewProps> {
    render() {
        const {role, name, port, host} = this.props;
        return (
            <div className="py-8 bg-base-200">
                <div className="container mx-auto">
                    <div className="flex flex-col gap-4 justify-center">
                        <div className="flex gap-4">
                            <div className="btn btn-sm gap-2">
                                Connection
                                <code className="badge badge-success normal-case" onClick={() => {
                                    copy(`ws://${host}:${port}`);
                                }}>ws://{host}:{port}</code>
                            </div>
                            <div className="btn btn-sm gap-2" onClick={() => {
                                copy(name);
                            }}>
                                Name
                                <div className="badge badge-primary normal-case">{name}</div>
                                Role
                                <div className="badge badge-info normal-case">{role}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
