import React from "react";
import ReactJson from "react-json-view-ts";
import {Message, MessageType} from "../messages/MessageType";
import {CharacterOffer} from "../messages/CharacterOffer";
import {CharacterChoice} from "../messages/CharacterChoice";
import {CardOffer} from "../messages/CardOffer";
import {CardChoice} from "../messages/CardChoice";
import {HelloClient} from "../messages/HelloClient";
import copy from "copy-to-clipboard";

export class MessagesDisplay extends React.Component<{
    messages: Array<{ message: string, type: string }>,
    onSend: (message: string) => void
}> {

    trycatch = (func: () => any, fail: any) => {
        try {
            return func()
        } catch (e) {
            return fail
        }
    }
    renderType = (type: MessageType, message: object): JSX.Element => {
        const {onSend} = this.props;
        if (type === "CHARACTER_OFFER") {
            const characterOffer = message as CharacterOffer;
            const characterChoice: CharacterChoice = {
                message: "CHARACTER_CHOICE",
                data: {
                    characterChoice: characterOffer.data.characters[0]
                }
            }
            return <div className="flex gap-4 items-center">
                {type}
                <button className="btn btn-sm" onClick={() => {
                    onSend(JSON.stringify(characterChoice));
                }}>Send VALID CHARACTER CHOICE
                </button>
            </div>;
        }
        if (type === "HELLO_CLIENT") {
            const helloClient = message as HelloClient;
            return <div className="flex gap-4 items-center">
                {type}
                <code className="btn btn-sm btn-info normal-case" onClick={() => {
                    copy(helloClient.data.reconnectToken)
                }}>Copy: Reconnect Token</code>
            </div>;
        }
        if (type === "CARD_OFFER") {
            const cardOffer = message as CardOffer;
            const cardChoice: CardChoice = {
                message: "CARD_CHOICE",
                data: {
                    cards: [cardOffer.data.cards[0], cardOffer.data.cards[1], cardOffer.data.cards[2], cardOffer.data.cards[3], cardOffer.data.cards[4]]
                }
            }
            return <div className="flex gap-4 items-center">
                {type}
                <button className="btn btn-sm" onClick={() => {
                    onSend(JSON.stringify(cardChoice));
                }}>Send VALID CARD CHOICE
                </button>
            </div>;
        }
        return <>{type}</>;
    }
    renderMessage = (message: { message: string, type: string }): JSX.Element => {
        const object: any | null = this.trycatch(() => JSON.parse(message.message), null);
        if (object) {
            return (<tr>
                <th className="text-left">{message.type}</th>
                <th className="text-left">{this.renderType((object as Message).message, object)}</th>
                <td className="w-full">
                    <ReactJson
                        src={object}
                        theme="google"
                        displayObjectSize={false}
                        name={"received"}
                        displayDataTypes={false}
                        enableClipboard={false}
                        collapsed={1}
                    />
                </td>
            </tr>)
        }
        return (<tr>
                <th>{message.type}</th>
                <th></th>
                <td className="text-black w-full">{message.message}</td>
            </tr>
        );

    }

    render() {
        const {messages} = this.props
        return (
            <div className="py-8">
                <div className="container mx-auto">
                    <div className="overflow-x-auto">
                        <table className="table table-zebra w-full">
                            <thead>
                            <tr>
                                <th></th>
                                <th>Type</th>
                                <th>Message</th>
                            </tr>
                            </thead>
                            <tbody>
                            {messages.map(this.renderMessage)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}
