import {CardType} from "../messages/CardOffer";
import React from "react";

const CardSelectionButton = (props: { type: CardType, onSelect: (type: CardType) => void, disabled?: boolean }) => {
    return (
        <button disabled={props.disabled} className="btn btn-sm btn-secondary text-white font-bold" onClick={() => {
            props.onSelect(props.type);
        }}>{props.type.replace("_", " ")}</button>
    );
}
CardSelectionButton.defaultProps = {
    disabled: false,
}
export default CardSelectionButton;
