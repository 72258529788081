import {HelloServer, Role} from "../messages/HelloServer";
import React, {Component} from "react";
import {PlayerReady} from "../messages/PlayerReady";
import {Character} from "../messages/CharacterOffer";
import {CharacterChoice} from "../messages/CharacterChoice";
import {GoodbyeServer} from "../messages/GoodbyeServer";
import {Reconnect} from "../messages/Reconnect";
import {PauseRequest} from "../messages/PauseRequest";
import {CardType} from "../messages/CardOffer";
import {CardChoice} from "../messages/CardChoice";
import {FaRegWindowMaximize, FaTimes, FaTrash} from "react-icons/fa";
import CardSelectionButton from "./CardSelectionButton";

type SendPanelProps = { onSend: (message: string) => void, name: string, role: Role, onClearLog: () => void };

type SendPanelState = {
    characterChoicePopup: boolean,
    character: Character,
    reconnectPopup: boolean,
    reconnectToken: string,
    cardChoicePopup: boolean,
    cardSelection: [CardType, CardType, CardType, CardType, CardType],
    pauseRequestPopup: boolean,
    pauseRequest: boolean,
    playerReadyPopup: boolean,
    playerReady: boolean
};

export class SendPanel extends Component<SendPanelProps, SendPanelState> {

    constructor(props: SendPanelProps) {
        super(props);
        this.state = {
            characterChoicePopup: false,
            character: "ARAGORN",
            reconnectPopup: false,
            reconnectToken: "",
            cardChoicePopup: false,
            cardSelection: ["EMPTY", "EMPTY", "EMPTY", "EMPTY", "EMPTY"],
            pauseRequestPopup: false,
            pauseRequest: true,
            playerReadyPopup: false,
            playerReady: true
        }
    }

    sendHelloServer = () => {
        const {onSend, name, role} = this.props;
        const helloServer: HelloServer = {
            message: "HELLO_SERVER",
            data: {
                name,
                role
            }
        };
        onSend(JSON.stringify(helloServer))
    }

    sendPlayerReady = () => {
        const {onSend} = this.props;
        const {playerReady} = this.state;
        const playerReadyM: PlayerReady = {
            data: {
                ready: playerReady,
            },
            message: "PLAYER_READY"
        }
        onSend(JSON.stringify(playerReadyM));
        this.setState({playerReady: !playerReady});
    }

    openPlayerReady = () => {
        this.setState({playerReadyPopup: true});
    }

    sendCharacterChoice = (character: Character) => {
        const {onSend} = this.props;
        const characterChoice: CharacterChoice = {
            message: "CHARACTER_CHOICE",
            data: {
                characterChoice: character
            }
        }
        onSend(JSON.stringify(characterChoice));
    }

    openCharacterChoice = () => {
        this.setState({characterChoicePopup: true})
    }

    openCardChoice = () => {
        this.setState({cardChoicePopup: true, cardSelection: ["EMPTY", "EMPTY", "EMPTY", "EMPTY", "EMPTY"],})
    }

    openReconnect = () => {
        this.setState({reconnectPopup: true})
    }

    sendGoodbyeServer = () => {
        const {onSend} = this.props;
        const goodbyeServer: GoodbyeServer = {
            data: {},
            message: "GOODBYE_SERVER"
        }
        onSend(JSON.stringify(goodbyeServer));
    }

    sendReconnect = () => {
        const {name, onSend} = this.props;
        const {reconnectToken} = this.state;
        const reconnect: Reconnect = {
            message: "RECONNECT",
            data: {
                reconnectToken,
                name
            }
        }
        onSend(JSON.stringify(reconnect));
    }
    sendPauseRequest = () => {
        const {onSend, name} = this.props;
        const {pauseRequest} = this.state;
        const pauseRequestM: PauseRequest = {
            data: {
                pause: pauseRequest,
                playerName: name
            },
            message: "PAUSE_REQUEST",
        }
        onSend(JSON.stringify(pauseRequestM));
        this.setState({pauseRequest: !pauseRequest});
    }

    openPauseRequest = () => {
        this.setState({pauseRequestPopup: true});
    }

    sendCardChoice = () => {
        const {cardSelection} = this.state;
        const {onSend} = this.props;
        if (cardSelection) {
            const cardChoice: CardChoice = {
                message: "CARD_CHOICE",
                data: {
                    cards: cardSelection
                }
            }
            onSend(JSON.stringify(cardChoice));
        }
    }

    addToCardSelection = (type: CardType) => {
        const {cardSelection} = this.state;
        if (cardSelection.includes("EMPTY")) {
            const index = cardSelection.indexOf("EMPTY");
            cardSelection[index] = type;
            this.setState({cardSelection});
        }
    }
    clearLog = () => {
        const {onClearLog} = this.props;
        onClearLog();
    }


    render() {
        const {
            characterChoicePopup,
            character,
            reconnectPopup,
            reconnectToken,
            cardChoicePopup,
            cardSelection,
            pauseRequestPopup,
            pauseRequest,
            playerReadyPopup,
            playerReady
        } = this.state;
        return (
            <>
                <div className="py-8 bg-base-200">
                    <div className="container mx-auto">
                        <div className="flex flex-col gap-4 justify-center">
                            <div className="flex gap-4">
                                <div className="btn btn-sm btn-info gap-2" onClick={this.sendHelloServer}>
                                    HELLO SERVER
                                </div>
                                <div className="btn btn-sm btn-info gap-2" onClick={this.openPlayerReady}>
                                    PLAYER READY <FaRegWindowMaximize/>
                                </div>
                                <div className="btn btn-sm btn-info gap-2" onClick={this.openCharacterChoice}>
                                    CHARACTER CHOICE  <FaRegWindowMaximize/>
                                </div>
                                <div className="btn btn-sm btn-info gap-2" onClick={this.openCardChoice}>
                                    CARD CHOICE <FaRegWindowMaximize/>
                                </div>
                            </div>
                            <div className="flex gap-4">
                                <div className="btn btn-sm btn-warning gap-2" onClick={this.openReconnect}>
                                    RECONNECT <FaRegWindowMaximize/>
                                </div>
                                <div className="btn btn-sm btn-warning gap-2" onClick={this.openPauseRequest}>
                                    PAUSE REQUEST <FaRegWindowMaximize/>
                                </div>
                                <div className="btn btn-sm btn-warning gap-2" onClick={this.sendGoodbyeServer}>
                                    GOODBYE SERVER
                                </div>
                                <div className="btn btn-sm btn-error gap-2 font-bold" onClick={this.clearLog}>
                                    <FaTrash/> Clear Log
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`modal ${characterChoicePopup ? 'modal-open' : ""}`}>
                    <div className="modal-box">
                        <h3 className="font-bold text-xl text-center">Character Choice</h3>
                        <div className="flex items-center justify-center flex-col my-4 gap-4">
                            <div>
                                <select className="select select-bordered w-full max-w-xs" value={character}
                                        onChange={(event) => {
                                            this.setState({character: event.target.value as Character})
                                        }}>
                                    <option value="FRODO">FRODO</option>
                                    <option value="SAM">SAM</option>
                                    <option value="LEGOLAS">LEGOLAS</option>
                                    <option value="GIMLI">GIMLI</option>
                                    <option value="GANDALF">GANDALF</option>
                                    <option value="ARAGORN">ARAGORN</option>
                                    <option value="GOLLUM">GOLLUM</option>
                                    <option value="GALADRIEL">GALADRIEL</option>
                                    <option value="BOROMIR">BOROMIR</option>
                                    <option value="BAUMBART">BAUMBART</option>
                                    <option value="MERRY">MERRY</option>
                                    <option value="PIPPIN">PIPPIN</option>
                                    <option value="ARWEN">ARWEN</option>
                                </select>
                            </div>
                        </div>
                        <div className="modal-action">
                            <button type="button" className="btn btn-sm" onClick={() => {
                                this.setState({characterChoicePopup: false});
                            }}>Close
                            </button>
                            <button type="button" className="btn btn-sm btn-primary" onClick={() => {
                                character && this.sendCharacterChoice(character);
                                this.setState({characterChoicePopup: false});
                            }}>Send
                            </button>
                        </div>
                    </div>
                </div>
                <div className={`modal ${reconnectPopup ? 'modal-open' : ""}`}>
                    <div className="modal-box">
                        <h3 className="font-bold text-xl text-center">Reconnect</h3>
                        <div className="flex items-center justify-center flex-col my-4 gap-4 w-full">
                            <div className="form-control w-full">
                                <label className="label">
                                    <span className="label-text">Reconnect Token</span>
                                </label>
                                <input type="text" placeholder="Reconnect Token" value={reconnectToken}
                                       onChange={(event) => {
                                           this.setState({reconnectToken: event.target.value});
                                       }}
                                       className="input input-sm input-bordered w-full"/>
                            </div>
                        </div>
                        <div className="modal-action">
                            <button type="button" className="btn btn-sm" onClick={() => {
                                this.setState({reconnectPopup: false});
                            }}>Close
                            </button>
                            <button type="button" className="btn btn-sm btn-primary" onClick={() => {
                                this.sendReconnect();
                                this.setState({reconnectPopup: false});
                            }}>Send
                            </button>
                        </div>
                    </div>
                </div>
                <div className={`modal ${cardChoicePopup ? 'modal-open' : ""}`}>
                    <div className="modal-box w-1/2 max-w-5xl">
                        <h3 className="font-bold text-xl text-center">Card Choice</h3>
                        <div className="w-1/2 mx-auto grid grid-cols-3 items-center justify-center my-4 gap-4">
                            <CardSelectionButton type={"MOVE_1"} onSelect={this.addToCardSelection}
                                                 disabled={cardSelection.filter(e => e === "MOVE_1").length >= 5 || cardSelection.filter(e => e === "EMPTY").length === 0}/>
                            <CardSelectionButton type={"MOVE_2"} onSelect={this.addToCardSelection}
                                                 disabled={cardSelection.filter(e => e === "MOVE_2").length >= 3 || cardSelection.filter(e => e === "EMPTY").length === 0}/>
                            <CardSelectionButton type={"MOVE_3"} onSelect={this.addToCardSelection}
                                                 disabled={cardSelection.filter(e => e === "MOVE_3").length >= 1 || cardSelection.filter(e => e === "EMPTY").length === 0}/>
                            <CardSelectionButton type={"MOVE_BACK"} onSelect={this.addToCardSelection}
                                                 disabled={cardSelection.filter(e => e === "MOVE_BACK").length >= 1 || cardSelection.filter(e => e === "EMPTY").length === 0}/>
                            <CardSelectionButton type={"AGAIN"} onSelect={this.addToCardSelection}
                                                 disabled={cardSelection.filter(e => e === "AGAIN").length >= 2 || cardSelection.filter(e => e === "EMPTY").length === 0}/>
                            <CardSelectionButton type={"LEFT_TURN"} onSelect={this.addToCardSelection}
                                                 disabled={cardSelection.filter(e => e === "LEFT_TURN").length >= 3 || cardSelection.filter(e => e === "EMPTY").length === 0}/>
                            <CardSelectionButton type={"RIGHT_TURN"} onSelect={this.addToCardSelection}
                                                 disabled={cardSelection.filter(e => e === "RIGHT_TURN").length >= 3 || cardSelection.filter(e => e === "EMPTY").length === 0}/>
                            <CardSelectionButton type={"LEMBAS"} onSelect={this.addToCardSelection}
                                                 disabled={cardSelection.filter(e => e === "LEMBAS").length >= 1 || cardSelection.filter(e => e === "EMPTY").length === 0}/>
                            <CardSelectionButton type={"U_TURN"} onSelect={this.addToCardSelection}
                                                 disabled={cardSelection.filter(e => e === "U_TURN").length >= 1 || cardSelection.filter(e => e === "EMPTY").length === 0}/>
                        </div>
                        <div className="flex items-center justify-center mt-8 gap-4 w-full">
                            {cardSelection && cardSelection.map((card, index) => (
                                    <div className="btn btn-sm gap-2" onClick={() => {
                                        cardSelection[index] = "EMPTY";
                                        this.setState({cardSelection});
                                    }}>{card === "EMPTY" ? false : (<><FaTimes/>{card.replace("_", " ")}</>)}</div>
                                )
                            )
                            }
                        </div>
                        <div className="modal-action">
                            <button type="button" className="btn btn-sm" onClick={() => {
                                this.setState({cardChoicePopup: false});
                            }}>Close
                            </button>
                            <button type="button" className="btn btn-sm btn-primary" onClick={() => {
                                this.sendCardChoice();
                                this.setState({cardChoicePopup: false});
                            }}>Send
                            </button>
                        </div>
                    </div>
                </div>
                <div className={`modal ${pauseRequestPopup ? 'modal-open' : ""}`}>
                    <div className="modal-box">
                        <h3 className="font-bold text-xl text-center">Pause Request</h3>
                        <div className="flex items-center justify-center flex-col my-4 gap-4 w-full">
                            <div className="form-control">
                                <label className="label cursor-pointer gap-2">
                                    <span className="label-text text-xl text-info">Pause</span>
                                    <input type="checkbox" checked={pauseRequest} className="checkbox checkbox-lg"
                                           onChange={() => {
                                               this.setState({pauseRequest: !pauseRequest});
                                           }}/>
                                </label>
                            </div>
                        </div>
                        <div className="modal-action">
                            <button type="button" className="btn btn-sm" onClick={() => {
                                this.setState({pauseRequestPopup: false});
                            }}>Close
                            </button>
                            <button type="button" className="btn btn-sm btn-primary" onClick={() => {
                                this.sendPauseRequest();
                                this.setState({pauseRequestPopup: false});
                            }}>Send
                            </button>
                        </div>
                    </div>
                </div>
                <div className={`modal ${playerReadyPopup ? 'modal-open' : ""}`}>
                    <div className="modal-box">
                        <h3 className="font-bold text-xl text-center">Player Ready</h3>
                        <div className="flex items-center justify-center flex-col my-4 gap-4 w-full">
                            <div className="form-control">
                                <label className="label cursor-pointer gap-2">
                                    <span className="label-text text-xl text-info">Ready?</span>
                                    <input type="checkbox" checked={playerReady} className="checkbox checkbox-lg"
                                           onChange={() => {
                                               this.setState({playerReady: !playerReady});
                                           }}/>
                                </label>
                            </div>
                        </div>
                        <div className="modal-action">
                            <button type="button" className="btn btn-sm" onClick={() => {
                                this.setState({playerReadyPopup: false});
                            }}>Close
                            </button>
                            <button type="button" className="btn btn-sm btn-primary" onClick={() => {
                                this.sendPlayerReady();
                                this.setState({playerReadyPopup: false});
                            }}>Send
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}


