import {Role} from "../messages/HelloServer";
import React from "react";

type InitialDialogProps = {
    initialDialog: boolean,
    host: string,
    port: number,
    name: string,
    role: Role
    onHostChange: (host: string) => void,
    onPortChange: (port: number) => void,
    onNameChange: (name: string) => void,
    onRoleChange: (role: Role) => void,
    onConnect: () => void,
}
export const InitialDialog = (props: InitialDialogProps) => {
    const {
        initialDialog,
        host,
        onHostChange,
        onNameChange,
        onRoleChange,
        name,
        role,
        onPortChange,
        port,
        onConnect
    } = props;
    return (
        <div className={`modal ${initialDialog && 'modal-open'}`}>
            <div className="modal-box">
                <div className="flex flex-col gap-4">
                    <h3 className="font-bold text-lg">Server Websocket Connection</h3>
                    <div className="flex items-center">
                        <code>ws://</code>
                        <input type="text" placeholder="Server Host" value={host}
                               onChange={(event) => {
                                   onHostChange(event.target.value);
                               }}
                               className="input input-sm input-bordered w-full max-w-xs"/>
                        <code>:</code>
                        <input type="number" placeholder="Port" value={port}
                               onChange={(event) => {
                                   onPortChange(Number.parseInt(event.target.value));
                               }}
                               className="input input-sm input-bordered max-w-xs"/>
                    </div>
                    <div className="flex justify-center gap-4">
                        <div className="form-control w-full max-w-xs">
                            <label className="label">
                                <span className="label-text">Name</span>
                            </label>
                            <input type="text" placeholder="Name" value={name}
                                   onChange={(event) => {
                                       onNameChange(event.target.value);
                                   }}
                                   className="input input-sm input-bordered w-full max-w-xs"/>
                        </div>
                        <div className="form-control w-full max-w-xs">
                            <label className="label">
                                <span className="label-text">Role</span>
                            </label>
                            <select className="select select-sm select-bordered" value={role} onChange={(e) => {
                                onRoleChange(e.target.value as Role);
                            }}>
                                <option value="PLAYER">Player</option>
                                <option value="AI">Ai</option>
                                <option value="SPECTATOR">Spectator</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="modal-action">
                    <button type="button" className="btn btn-sm btn-primary" onClick={() => {
                        onConnect();
                    }}>Connect
                    </button>
                </div>
            </div>
        </div>
    );
};
